import React, { useState, useEffect } from "react";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import { useMedia } from "use-media";

import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_8/b8_1.webp");
const image2 = require("../../../assets/images/blog_8/b8_2.png");
const image3 = require("../../../assets/images/blog_8/b8_3.jpg");
const image4 = require("../../../assets/images/blog_8/b8_4.jpg");
const image5 = require("../../../assets/images/blog_8/b8_5.jpg");
const image6 = require("../../../assets/images/blog_8/b8_6.jpg");
const image8 = require("../../../assets/images/blog_8/b8_8.png");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Enable chatops with JumpCloud integration with Chatbot | Workativ Blog"
        description="Learn how JumpCloud customers are automating repetitive access management tasks using workativ assistant chatbot and streamline various employees IT requests."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            How to enable JumpCloud ChatOps with Workativ
                            Assistant
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          If you’re using JumpCloud as your business’
                          directory-as-a-service (DaaS) solution to
                          authenticate, authorize, and manage users, then it’s
                          high time you made the whole process effortless for
                          your employees. Why? Because many a time, businesses
                          tend to invest more on the technologies that improve
                          the customer experience and neglect to do the same for
                          their employees. What businesses don’t realize is that
                          they can improve their customer experience multifold
                          by improving employee experience.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Lindsay Willott, chief executive of Customer
                          Thermometer,{" "}
                          <a href="https://www.raconteur.net/the-hive/customer-employee-experience-convergence/">
                            says:
                          </a>{" "}
                          “Customer and employee experiences are intrinsically
                          linked, and businesses need to care fanatically about
                          both to maximize growth and success. With the arrival
                          of Generation Z in the workplace – those that have
                          grown up in a connected world – the need for better
                          employee experience is only heightened.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          “Not only do these people have an innate understanding
                          of personal branding and marketing that comes from
                          publishing news about themselves on Facebook and
                          Instagram, but they are also the feedback generation.
                          They are used to liking people’s Facebook posts,
                          retweeting, even leaving feedback about businesses.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          “The key is this: employees who feel listened to and
                          supported are nearly five times more likely to feel
                          engaged at work. If their satisfaction isn’t regularly
                          measured, then every employee will be fighting
                          internal battles just to be productive.”
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Hence investing in technologies that improve employee
                          experience is important to increase the productivity
                          as well as the overall morale of employees. Where can
                          you start?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          You can start by{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            automating employee IT support via self-service
                            chatbots.
                          </a>{" "}
                          How you ask? Why with Workativ Assistant of course!
                          Workativ Assistant is an AI-powered no-code platform
                          for building contextual chatbots with automated
                          workflows for various business apps. Workativ
                          Assistant’s chatbots are easy to add to your business’
                          communications hub like{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                            Slack
                          </a>{" "}
                          or{" "}
                          <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                            Microsoft Teams.&nbsp;
                          </a>
                          Employees can self-serve their IT issues on-the-go by
                          just having a quick chat with Workativ Assistant’s
                          chatbot without logging in and out of a convoluted
                          self-service portal. Sounds simple, right?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If you’re using JumpCloud, you can easily integrate it
                          with Workativ Assistant and automate tasks on it in
                          just a few clicks.
                        </p>
                        <h2 class="font-section-sub-header-small line-height-2">
                          Build JumpCloud Automations that scale with ChatOps
                        </h2>
                        <img loading="lazy" src={image2} className="mb-5"></img>
                        <p class="font-section-normal-text line-height-2">
                          By integrating JumpCloud and Workativ Assistant, you
                          streamline your workplace IT support ChatOps to
                          connect employees, processes, and automations into a
                          transparent workflow. Let’s take a look at some of the
                          automations you get with Workativ Assistant and
                          <a href="https://workativ.com/conversational-ai-platform/jumpcloud-chatbot">
                            {" "}
                            JumpCloud integration.
                          </a>
                        </p>
                        <h5 class="font-section-sub-header-small line-height-2">
                          1. Suspend and Restore User Accounts
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With JumpCloud and Workativ Assistant, your help desk
                          agents can quickly manage user access to company
                          resources via the Suspend/Unsuspend User automations
                          on your business’ Slack/Microsoft Teams channel.
                          Suspend User Automation lets agents freeze access to
                          company resources when employees are on long-term
                          leave or if their identity is compromised. Agents can
                          immediately restore accounts to their previous access
                          level when employees return from leave or after their
                          identity is secured.
                        </p>
                        <h5 class="font-section-sub-header-small line-height-2">
                          2. Streamlining Secure User Controls with JumpCloud
                          and Workativ Assistant
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant, create Slack-based automated
                          JumpCloud workflows that would allow help desk agents
                          to securely and easily add an employee, update
                          information about an employee, retrieve details about
                          an employee, or remove an employee on
                          JumpCloud—without leaving the chat app.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Bottom-line?{" "}
                          <a href="https://workativ.com/conversational-ai-platform/traditional-support">
                            Get rid of the tedious and time-consuming
                            traditional IT support model
                          </a>{" "}
                          and switch to Workativ Assistant’s JumpCloud chatbot
                          for IT self-service that’s faster and more secure.{" "}
                        </p>
                      </div>

                      <div>
                        <h5 class="font-section-sub-header-small">
                          Smarter IT Process Automation via JumpCloud + Workativ
                          Assistant + Other Business Apps
                        </h5>

                        <h5 class="font-section-sub-header-small">
                          1. JumpCloud and Azure Active Directory Integration
                          (AAD)
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          The Workativ Assistant +{" "}
                          <a href="https://workativ.com/workflow-automation-platform/integrations/jumpcloud+microsoft-azure-ad">
                            Azure AD + JumpCloud integration
                          </a>{" "}
                          makes user provisioning and deprovisioning a fairly
                          straightforward process. Imagine an existing JumpCloud
                          user, let’s call him User A. Using Workativ Assistant
                          if we set up an automation to push him into Azure AD
                          after getting details about him from JumpCloud,
                          Workativ Assistant naturally creates a user named
                          “User A” and incorporates him into Azure AD.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Simply put, what goes into JumpCloud, can get pushed
                          up into AAD.
                        </p>
                        <img loading="lazy" src={image3} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. JumpCloud and Office 365 Integration (O365)
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          You can easily connect O365 with Workativ Assistant
                          via JumpCloud’s Single Sign-On (SSO). Web application
                          SSO is a great tool for end-users convenience, as it
                          provides one central area for a user to log in to
                          before accessing the sites they need to visit. Once
                          logged in to their JumpCloud account, users only need
                          to select the application they’d want on Workativ
                          Assistant like O365. After selecting O365, the
                          end-user will be authenticated to Office 365 and they
                          gain access. It’s as easy as that.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          After logging in to Office 365, you can set up an
                          automation on Workativ Assistant such that, after
                          getting the details about a user from JumpCloud, the
                          user will be added to an Office 365 email distribution
                          group automatically.
                        </p>
                        <img loading="lazy" src={image4} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          3. JumpCloud and G Suite Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          You can use JumpCloud’s SAML 2.0-based SSO support in
                          concert with Workativ Assistant’s G Suite integration
                          to allow help desk agents to connect their G Suite
                          account with Workativ Assistant from their JumpCloud
                          User Portals alongside their other SAML-enabled web
                          apps.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          After logging in to G Suite, you can set up an
                          automation on Workativ Assistant such that, after
                          adding a user on JumpCloud, an email gets sent to the
                          user with the Google Drive link for the New Employee
                          Guidelines document.{" "}
                        </p>

                        <h5 class="font-section-sub-header-small">
                          How you can effortlessly create a JumpCloud Chatbot
                          using Workativ Assistant
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen the endless possibilities of what
                          integrating Workativ Assistant and JumpCloud and your
                          existing business apps can do for you, let’s see how
                          you can go about the same.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading="lazy" src={image5} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>
                        <img loading="lazy" src={image6} className="mb-3"></img>
                        <img loading="lazy" src={image8} className="mb-5"></img>
                      </div>

                      <NocodeWrapper />

                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small-bold">
                          What all these mean for you
                        </h6>
                        <h6 className="font-section-sub-header-small ">
                          1. Intuitive conversational AI for an awesome employee
                          experience
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          No one prefers boring manual data entry portals even
                          if it’s JumpCloud. With Workativ Assistant’s chatbots,
                          provide the{" "}
                          <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                            seamless conversational self-service
                          </a>{" "}
                          that your employees would appreciate.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. All from the comfort of your chat hub
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant’s JumpCloud AI chatbot proactively
                          resides on your Slack/Microsoft Teams workspace
                          waiting to resolve your employees’ IT queries like
                          JumpCloud{" "}
                          <a href="https://workativ.com/assistant/workflow-automation">
                            self-service password reset or account unlock
                          </a>{" "}
                          in an instant, 24×7.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. Faster business processes
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          No more logging in and out of different applications
                          and switching between them endlessly. With Workativ
                          Assistant, you make it easier on your employees by
                          clubbing existing business apps together and providing
                          their services through your business’ Slack or
                          Microsoft Teams workspace. Employees will then just
                          have to open their Slack or Microsoft Teams mobile/web
                          app to do what they want by having a chat with
                          Workativ Assistant’s chatbot there, on-the-go, 24×7.
                        </p>
                        <h6 className="font-section-sub-header-small ">
                          4. Improved IT support experience for employees{" "}
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-process-automation">
                            No more repetitive calls/tickets to help desk.
                          </a>{" "}
                          With Workativ assistant’s chatbot, you make sure your
                          employees don’t have to wait on endless calls to get
                          what they’re looking for which in turn leads to your
                          help desk agents’ productivity increase, letting them
                          concentrate on the complex tasks at hand that requires
                          a human touch.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Liked what you read about Workativ Assistant? Try
                          Workativ Assistant today by signing up for a FREE
                          trial at
                          <a href="https://workativ.com/">
                            &nbsp;workativ.com
                          </a>{" "}
                          and experience the difference yourself.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free JumpCloud Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="jumpcloud chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
